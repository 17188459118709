<template>
  <div class="error-wrapper">
    <div class="error">
      <h1>401</h1>
      <p>Unauthorized</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Unauthorized"
}
</script>

<style lang="scss" scoped>
.error-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .error {
    text-align: center;

    h1 {
      color: #C00;
    }

    p {
      color: #333;
    }
  }

}
</style>